import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store.js'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import AuthService from './services/auth-service'
import VCalendar from 'v-calendar'
import Axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faXmark, faCheck, faMoon, faCloudMoon, faSun, faStarAndCrescent, faAdd, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import logger from './plugin/logger.js'

Vue.config.productionTip = false
Vue.prototype.$http = Axios
Vue.prototype.$auth = new AuthService()

const plugin = {
  install: function (Vue, options) {
    Vue.logger = logger
    Vue.prototype.$logger = logger
  }
}

Vue.use(plugin)
Vue.use(BootstrapVue)
Vue.use(VCalendar, { componentPrefix: 'vc' })
Vue.component('v-icon', Icon)

/* add icons to the library */
library.add(faXmark)
library.add(faCheck)
library.add(faAdd)
library.add(faMoon)
library.add(faCloudMoon)
library.add(faSun, faStarAndCrescent)
library.add(faLayerGroup)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.mixin({
  renderError (h, err) {
    console.error('Render error in:', this.$options.name || 'Anonymous Component')
    console.error('Error:', err.message)
    console.error('Stack Trace:', err.stack)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
